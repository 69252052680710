import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"

// Layout
const MainLayout = React.lazy(() => import("../layouts/main"))

const AdminLoginPage = React.lazy(() => import("../pages/public/admin/Login"))
const AdminResetPasswordPage = React.lazy(
  () => import("../pages/public/admin/ResetPassword"),
)
const AdminForgotPasswordPage = React.lazy(
  () => import("../pages/public/admin/ForgotPassword"),
)

const PublicAdminRoutes = () =>
  useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: UrlSlugType.ADMIN_LOGIN, element: <AdminLoginPage /> },
        {
          path: UrlSlugType.ADMIN_RESET_PASSWORD,
          element: <AdminResetPasswordPage />,
        },
        {
          path: UrlSlugType.ADMIN_FORGOT_PASSWORD,
          element: <AdminForgotPasswordPage />,
        },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.HOME_PAGE} /> },
  ])

export default PublicAdminRoutes
