import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CategoryType } from "../../utils/types/assessmentTypes"

export type AssessmentStateType = {
  answers?: CategoryType[]
  currentQuestionNumber?: number
  currentCategoryNumber?: number
}

const initialState: AssessmentStateType = {
  answers: [],
  currentQuestionNumber: 0,
  currentCategoryNumber: 0,
}

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {
    setAssessmentAnswers: (
      state,
      action: PayloadAction<any>,
    ): AssessmentStateType => ({
      ...state,
      answers: action.payload.answers,
      currentQuestionNumber: action.payload.currentQuestionNumber,
      currentCategoryNumber: action.payload.currentCategoryNumber,
    }),
    clearAssessmentData: () => initialState,
  },
})

export const { setAssessmentAnswers, clearAssessmentData } =
  assessmentSlice.actions
export default assessmentSlice.reducer
