export enum UrlSlugType {
  // Public url
  HOME_PAGE = "/",
  LOGIN = "/login",
  REGISTER = "/sign-up",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  APPROVE_USER = "/approve-request/assessment-category/:assessmentCategoryId",
  CONFIRM_USER = "/confirm-user/assessment-category/:assessmentCategoryId",

  // User Url
  WELCOME = "/welcome",
  ASSESSMENT = "/assessment",
  ASSESSMENT_RESULTS = "/assessment-results",

  // Admin Url
  ADMIN_LOGIN = "/admin/login",
  ADMIN_FORGOT_PASSWORD = "/admin/forgot-password",
  ADMIN_RESET_PASSWORD = "/admin/reset-password",
  EDIT_QUESTION = "/admin/edit-question",
}
