export enum ApiSlug {
  // Auth related
  SIGN_UP = "/auth/register",
  LOGIN = "/auth/login",
  ADMIN_LOGIN = "/auth/admin/login",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  REFRESH_TOKEN = "/auth/refresh-token",
  APPROVE_USER = "/auth/approve-request",
  CONFIRM_USER = "/auth/confirm-user",
  ME = "auth/me",
  REQUEST_ACCESS = "/auth/access-requests",

  // User
  GET_ASSESSMENT_CATEGORY = "/assessment-categories/{assessmentCategoryId}",
  GET_ALL_QUESTIONS = "/questions/assessment-category/{assessmentCategoryId}/question-category",
  SUBMIT_ASSESSMENT = "/assessments/assessment-category/{id}",

  // Admin
  GET_ASSESSMENT_CATEGORIES = "/assessment-categories",
  ADD_ASSESSMENT_CATEGORIES = "/assessment-categories",
  GET_ALL_CATEGORIES = "/question-categories/assessment-category/{assessmentCategoryId}",
  GET_QUESTIONS_BY_CATEGORY_KEY = "/questions/assessment-category/{assessmentCategoryId}/question-category/{questionCategoryKey}",
  UPDATE_CATEGORY = "/question-categories/{id}/assessment-category/{assessmentCategoryId}",
  UPDATE_QUESTION = "/questions/{id}/assessment-category/{assessmentCategoryId}",
  UPDATE_QUESTION_OPTIONS = "/questions/option/{id}/assessment-category/{assessmentCategoryId}",
}
