import { Typography } from "../typography"
import { PopupButton } from "react-calendly"

interface TextWithImageAndButtonTypes {
  buttonLink?: string
  buttonText?: string
  externalLink?: boolean
  heading?: string
  title: string
  description: string
  imageLink: string
  imageAlt: string
  className?: string
}

export const TextWithImageAndButton = (props: TextWithImageAndButtonTypes) => {
  return (
    <div className="container max-w-[1198px] w-full rounded-[30px] bg-secondary_bg h-full flex flex-col md:flex-row">
      <img
        className="w-full md:w-[55%] rounded-[8px] md:rounded-[30px] md:rounded-l-[30px] md:rounded-r-none"
        src={props.imageLink}
        alt={props.imageAlt}
      />
      <div className="container bg-[#323232] rounded-[8px] md:rounded-[30px] md:rounded-r-[30px] md:rounded-l-none w-full md:w-[45%] p-4 md:p-[40px] lg:pl-[62px] lg:pr-[42px] md:pt-[64px] lg:pb-[24px] mt-[24px] md:mt-0">
        <Typography
          variant="h6"
          className="max-w-none md:max-w-[280px] text-left font-medium"
        >
          {props.heading}
        </Typography>
        <Typography
          variant="h4"
          className="max-w-none md:max-w-[270px] mb-[24px] md:mb-[54px] text-left font-bold"
        >
          {props.title}
        </Typography>
        <Typography
          variant="p"
          className={`max-w-none md:max-w-[355px] ${
            props.buttonLink ? "mb-[24px] md:mb-[55px]" : ""
          } text-left text-[12px] lg:text-[22px] leading-[24px] lg:leading-[34px] font-normal`}
        >
          {props.description}
        </Typography>
        {props.buttonLink ? (
          <PopupButton
            url={import.meta.env.VITE_CALENDLY}
            rootElement={document.getElementById("root") as HTMLElement}
            text={props.buttonText ? props.buttonText : "Book a call"}
            className="h-10 lg:h-12 flex items-center w-fit rounded-full text-[16px] lg:text-[20px] drop-shadow-md font-normal px-10 bg-orange text-white"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
