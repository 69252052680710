import { useRef, MouseEvent } from "react"
import { FaqAccordion } from "../../../../components/core/shared/faq"
import { BsArrowDown, BsArrowUp } from "react-icons/bs"
import NavBar from "../../../../components/core/shared/navbar"
import { TextWithImageAndButton } from "../../../../components/core/textWithImageAndButton"
import { Typography } from "../../../../components/core/typography"
import { LandingPageVideo } from "../../../../components/core/video"
import { TextWithImage } from "../../../../components/core/textWithImage"
import PrimaryButton from "../../../../components/core/buttons"
import { useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"

import content from "../../../../data/content.config.json"
import { useRequestAccessMutation } from "../../../../services/authService"
import toast from "react-hot-toast"
import HomePage from "../Home"
import { AssessmentCategory } from "../../../../utils/types/assessmentTypes"

const LandingPage = () => {
  const topRef = useRef(null)
  const optRef = useRef(null)
  const user = useSelector((state: any) => state.auth.user)

  const [requestAccess] = useRequestAccessMutation()

  const path = window.location?.pathname?.split("/")?.at(1) || ""

  const landingPageContent = (content as any)[path]?.landing

  const isMobileView = useMediaQuery({ query: "(max-width: 600px)" })

  const handleClick = (event: any, ref: any) => {
    event.preventDefault()
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleRequestAccess = (event: any) => {
    event?.preventDefault()
    requestAccess({ data: { assessmentCategoryPath: path } })
      .then(() => {
        toast.success("Access requested successfully")
      })
      .catch((error: any) => toast.error(error.message))
  }

  const getCTAButtons = () => {
    if (!user) {
      return (
        <>
          <PrimaryButton
            to="login"
            className="font-medium w-[279px] flex justify-center z-[100]"
          >
            I’m an existing user
          </PrimaryButton>
          <PrimaryButton
            to="sign-up"
            className="font-medium bg-[#EBE7E2] text-black w-[279px] flex justify-center z-[100]"
          >
            I’m new here 👋
          </PrimaryButton>
        </>
      )
    } else if (
      !user.allowedAssessmentCategories.find(
        (assessmentCategory: { assessmentCategoryPath: string }) =>
          assessmentCategory.assessmentCategoryPath === path,
      )
    ) {
      return (
        <PrimaryButton className="font-bold" onClick={handleRequestAccess}>
          {" "}
          Request Access{" "}
        </PrimaryButton>
      )
    } else {
      return (
        <PrimaryButton to="/welcome" className="font-bold">
          {" "}
          Start Assessment{" "}
        </PrimaryButton>
      )
    }
  }

  return path && (Object as any).values(AssessmentCategory).includes(path) ? (
    <>
      <div
        className={`container mx-auto bg-no-repeat bg-cover max-w-full bg-black bg-[url(/img/hero-background.png)] h-screen`}
        ref={topRef}
      >
        <NavBar />
        <div className="container relative mx-auto px-4">
          <div
            className={`container mx-auto max-w-[843.86px] ${
              isMobileView ? `mt-[168px]` : `mt-[92.56px]`
            }`}
          >
            {!isMobileView && (
              <Typography
                variant="p"
                className="text-orange-dark flex items-center uppercase font-bold font-secondary tracking-[6.35px] mb-[26.68px]"
              >
                <span className="w-[41.89px] h-[2.12px] bg-orange-dark block mr-[14.88px]"></span>
                <span className="block">{landingPageContent.titleSubText}</span>
              </Typography>
            )}
            <Typography
              variant="h1"
              className="text-6xl lg:text-[90px] font-bold text-center md:text-left"
            >
              {landingPageContent.titleTextOne}
            </Typography>
            <Typography
              variant="h1"
              className="block capitalize text-center md:text-left"
            >
              {landingPageContent.titleTextTwo}
            </Typography>
            {isMobileView && (
              <Typography
                variant="p"
                className="text-orange-dark text-center uppercase font-bold font-secondary tracking-[6px] mt-[18px] mb-[132px]"
              >
                <span className="block">{landingPageContent.titleSubText}</span>
              </Typography>
            )}
            <div className="container z-10 mt-10 md:mt-[70px] flex items-center sm:justify-start gap-5 sm:gap-11 flex-col sm:flex-row">
              {getCTAButtons()}
            </div>
          </div>
          <p
            className="absolute z-10 -bottom-[15vh] right-4 sm:right-0 flex items-center text-white cursor-pointer gap-2 text-sm"
            onClick={(e: MouseEvent<HTMLParagraphElement>) =>
              handleClick(e, optRef)
            }
          >
            Scroll down <BsArrowDown className="h-5 w-5" />
          </p>
        </div>
        <span className="w-full z-0 h-[25vh] bg-gradient-to-t from-[#1D1D1D] to-transparent block absolute bottom-0"></span>
      </div>
      <div className="container px-8 sm:px-4 bg-bg-primary w-[100%] max-w-full">
        <div
          className="container py-10 md:py-20 lg:pt-[186px] mx-auto flex flex-col items-center justify-center max-w-[1231px]"
          id="opt-this"
          ref={optRef}
        >
          <Typography
            variant="h2"
            className="max-w-[770px] text-center font-medium"
          >
            {landingPageContent.whySection.title}
          </Typography>
          <TextWithImage
            title={landingPageContent.whySection.subSectionOne.title}
            description={landingPageContent.whySection.subSectionOne.content}
            image={landingPageContent.whySection.subSectionOne.image}
            alt={landingPageContent.whySection.subSectionOne.alt}
          />
          <div className="mt-[36px]">
            <TextWithImage
              title={landingPageContent.whySection.subSectionTwo.title}
              description={landingPageContent.whySection.subSectionTwo.content}
              image={landingPageContent.whySection.subSectionTwo.image}
              alt={landingPageContent.whySection.subSectionTwo.alt}
              className="sm:flex-row-reverse"
            />
          </div>
          <div className="mt-[36px]">
            <TextWithImage
              title={landingPageContent.whySection.subSectionThree.title}
              description={
                landingPageContent.whySection.subSectionThree.content
              }
              image={landingPageContent.whySection.subSectionThree.image}
              alt={landingPageContent.whySection.subSectionThree.alt}
            />
          </div>
        </div>
      </div>
      {landingPageContent.productSection && (
        <div className="container px-4 bg-bg-primary w-[100%] max-w-full">
          <div className="container py-20 lg:pt-[186px] mx-auto flex flex-col items-center justify-center">
            <Typography
              variant="h2"
              className="max-w-[770px] text-center font-medium"
            >
              {landingPageContent.productSection.title}
            </Typography>
            <LandingPageVideo
              title="Gapstars Video"
              className="mt-[40px] sm:mt-[80px] md:mt-[130px]"
              link={landingPageContent.productSection.productVideoURL}
            />
          </div>
        </div>
      )}
      <div className="container px-4 bg-bg-primary w-[100%] max-w-full">
        <div className="container py-20 lg:pt-[186px] mx-auto flex flex-col items-center justify-center">
          <TextWithImageAndButton
            imageLink={landingPageContent.bookACallSection.image}
            imageAlt={landingPageContent.bookACallSection.imageAlt}
            heading={landingPageContent.bookACallSection.titleTextOne}
            title={landingPageContent.bookACallSection.titleTextTwo}
            description={landingPageContent.bookACallSection.content}
            buttonLink="#"
            externalLink
            buttonText={landingPageContent.bookACallSection.buttonText}
          />
        </div>
      </div>
      <div
        className={`container px-4 mx-auto bg-no-repeat bg-cover max-w-full bg-bg-primary pt-26 lg:pt-32 pb-32 lg:pb-60`}
      >
        <div className="container relative mx-auto">
          <Typography
            variant="h3"
            className="text-2xl lg:text-[45px] tracking-[1%] font-bold"
          >
            FAQs
          </Typography>
          <FaqAccordion options={landingPageContent.faqSection} />
          <p
            className="absolute z-10 -bottom-20 lg:-bottom-32 right-0 flex items-center text-white cursor-pointer gap-2 text-sm"
            onClick={(e: MouseEvent<HTMLParagraphElement>) =>
              handleClick(e, topRef)
            }
          >
            Scroll up <BsArrowUp className="h-5 w-5" />
          </p>
        </div>
      </div>
    </>
  ) : (
    <HomePage />
  )
}

export default LandingPage
