import { useState, useEffect } from "react"
import Logo from "../../../../assets/svg/gapstars-logo.svg"
import ProgressData from "../../../../components/core/progress"
import SingleChoiceQuestion from "../../../../components/questions/singleChoiceQuestion"
import { useLazyGetQuestionsQuery } from "../../../../services/questionService"
import { setQuestions } from "../../../../store/slices/question.slice"
import { useDispatch, useSelector } from "react-redux"
import Splash from "../../../../components/core/splash"
import QuestionCategoryType, {
  OptionsType,
  QuestionType,
} from "../../../../utils/types/question.type"
import { useNavigate } from "react-router-dom"
import { UrlSlugType } from "../../../../utils/enums/UrlSlug"
import { useMediaQuery } from "react-responsive"
import { useSubmitAssessmentMutation } from "../../../../services/assessmentService"
import toast from "react-hot-toast"
import { deepCopy } from "../../../../utils/helpers/common"
import { CategoryType } from "../../../../utils/types/assessmentTypes"
import {
  clearAssessmentData,
  setAssessmentAnswers,
} from "../../../../store/slices/assessment.slice"

const Assessment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const questions = useSelector((state: any) => state.quiz.data)
  const userData = useSelector((state: any) => state.auth.user)
  const assessmentCategory = useSelector(
    (state: any) => state.assessmentCategory.assessmentCategory,
  )
  const assessmentData: {
    answers: CategoryType[]
    currentQuestionNumber: number
    currentCategoryNumber: number
  } = useSelector((state: any) => state.assessment)

  const [fetchQuestions, { isLoading: fetchQuestionLoading, isSuccess }] =
    useLazyGetQuestionsQuery()
  const [submitAssessment, { isLoading: assessmentSubmitLoading }] =
    useSubmitAssessmentMutation()

  const [questionCategoryNumber, setQuestionCategoryNumber] = useState(0)
  const [questionNumber, setQuestionNumber] = useState(0)
  const isMobileView = useMediaQuery({ query: "(max-width: 600px)" })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(
    questions?.[questionCategoryNumber]?.questions?.[questionNumber] || null,
  )
  const [displayFinishBtn, setDisplayFinishBtn] = useState(false)

  // For API input
  const [answers, setAnswers] = useState<CategoryType[]>(assessmentData.answers)

  useEffect(() => {
    setSelectedQuestion(
      questions?.[questionCategoryNumber]?.questions?.[questionNumber] || null,
    )
  }, [questionCategoryNumber, questionNumber, questions])

  useEffect(() => {
    let isComplete = true
    questions?.every((questionCategory: { id: string; questions: any[] }) => {
      const categoryAnswers = answers.find(
        (answer) => answer.categoryId === questionCategory.id,
      )
      if (
        categoryAnswers?.selectedOptions?.length !==
        questionCategory.questions.length
      ) {
        isComplete = false
      }
      return isComplete
    })

    setDisplayFinishBtn(isComplete)
  }, [answers, questionCategoryNumber, questionNumber, questions])

  const handleSelectAnswer = (question: any, option: OptionsType) => {
    if (selectedQuestion?.id !== question.id) {
      return
    }

    const category = answers.find(
      (answer) => answer.categoryId === question?.categoryId,
    )

    let questionCategory: CategoryType | null | undefined = deepCopy(category)

    const answerParams = {
      questionId: question?.id,
      selectedOption: option,
    }

    if (questionCategory && Object.keys(questionCategory).length > 0) {
      questionCategory.selectedOptions = [
        ...(questionCategory?.selectedOptions || []).filter(
          (option) => option.questionId !== answerParams.questionId,
        ),
        answerParams,
      ]
    } else {
      questionCategory = {
        categoryId: question?.categoryId,
        selectedOptions: [answerParams],
      }
    }

    setAnswers([
      ...answers.filter(
        (category) => category.categoryId !== questionCategory?.categoryId,
      ),
      questionCategory,
    ])

    setTimeout(() => {
      if (
        questionNumber ===
        questions[questionCategoryNumber].questions.length - 1
      ) {
        if (questions.length !== questionCategoryNumber + 1) {
          setQuestionCategoryNumber(questionCategoryNumber + 1)
          setQuestionNumber(0)
        }
      } else {
        setQuestionNumber(questionNumber + 1)
      }
      updateQuestionAndCategory(questionCategory)
    }, 300)
  }

  const updateQuestionAndCategory = (questionCategory: any) => {
    let nextQuestionNumber = questionNumber
    let nextCategoryNumber = questionCategoryNumber

    const isLastQuestionInCategory =
      questionNumber === questions[questionCategoryNumber].questions.length - 1
    const isLastCategory = questionCategoryNumber === questions.length - 1

    if (isLastQuestionInCategory) {
      if (!isLastCategory) {
        nextCategoryNumber += 1
        nextQuestionNumber = 0
      }
    } else {
      nextQuestionNumber += 1
    }

    if (
      questionCategoryNumber !== nextCategoryNumber ||
      questionNumber !== nextQuestionNumber
    ) {
      setQuestionCategoryNumber(nextCategoryNumber)
      setQuestionNumber(nextQuestionNumber)

      if (!(nextCategoryNumber === 0 && nextQuestionNumber === 0)) {
        dispatch(
          setAssessmentAnswers({
            answers: [
              ...answers.filter(
                (category) =>
                  category.categoryId !== questionCategory?.categoryId,
              ),
              questionCategory,
            ],
            currentQuestionNumber: nextQuestionNumber,
            currentCategoryNumber: nextCategoryNumber,
          }),
        )
      }
    }
  }

  const handleSubmitAssessment = async () => {
    setIsLoading(true)
    const res: any = await submitAssessment({
      data: { answers: answers, userId: userData?.id },
      assessmentCategoryId: assessmentCategory?.id,
    })

    dispatch(clearAssessmentData())

    if (res?.data?.status) {
      navigate(UrlSlugType.ASSESSMENT_RESULTS, {
        state: { ...res.data?.data },
      })
      setIsLoading(false)
    }
  }

  const handleQuestionSelect = (
    questionNumber: number,
    questionCategoryNumber: number,
  ) => {
    setQuestionNumber(questionNumber)
    setQuestionCategoryNumber(questionCategoryNumber)
  }

  useEffect(() => {
    fetchQuestions(assessmentCategory?.id)
      .unwrap()
      .then((data: any) => {
        dispatch(setQuestions(data?.data?.questionCategories))
      })
      .catch((error: any) => {
        toast.error("Something went wrong", { duration: 2000 })
        navigate(UrlSlugType.HOME_PAGE)
      })
  }, [dispatch, navigate, fetchQuestions, assessmentCategory])

  if (fetchQuestionLoading || assessmentSubmitLoading || isLoading) {
    return <Splash />
  }

  return (
    <div className="bg-bg-primary w-full h-[100vh] flex flex-col md:flex-row px-[22px] md:p-0">
      <div className="container w-full md:w-[35%] relative flex flex-col justify-center">
        <div className="w-full p-6 md:p absolute top-0 md:block hidden">
          <img
            src={Logo}
            alt="Gapstars Logo"
            className="w-[130px] cursor-pointer"
            onClick={() => navigate(UrlSlugType.HOME_PAGE)}
          />
        </div>
        <div className="p-8 md:p-[42px] lg:p-[74px] mt-[24px] mb-[32px] md:mb-[0] md:mt-[0]">
          {isSuccess &&
            questions &&
            !isMobileView &&
            questions.map((question: QuestionCategoryType, index: number) => (
              <ProgressData
                handleQuestionSelect={handleQuestionSelect}
                key={question.id}
                {...question}
                questionCategoryNumber={questionCategoryNumber}
                questionNumber={questionNumber}
                answers={answers}
                index={index}
              />
            ))}
          {isSuccess && questions && isMobileView && (
            <div className="mt-[64px]">
              <p className="capitalize text-[#FFF] text-[14px] text-center">
                {`(${questionCategoryNumber + 1}/${questions.length}) `}
                {questions[questionCategoryNumber].categoryTitle}
              </p>
              <div className="flex gap-x-[2px] mt-2">
                {questions[questionCategoryNumber].questions.map(
                  (quiz: QuestionType, index: number) => (
                    <div
                      className={`p-[2px] text-white ${
                        questionNumber > index
                          ? "bg-[#DA7F41]"
                          : "bg-[#D9D9D9] "
                      } w-[100px] rounded-[1px]`}
                    />
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="md:hidden absolute top-6">
        <img
          src={Logo}
          alt="Gapstars Logo"
          className="w-[68px] cursor-pointer"
          onClick={() => navigate(UrlSlugType.HOME_PAGE)}
        />
      </div>
      <div
        id="quiz-card"
        className={`w-full md:w-[55%] md:bg-white flex flex-col justify-center text-[#232323] relative z-[100]`}
        style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))" }}
      >
        <div className="bg-white p-[42px] md:p-[42px] lg:p-[74px] rounded-[31px] md:rounded-non">
          {isSuccess && questions && (
            <SingleChoiceQuestion
              categoryId={questions[questionCategoryNumber].id}
              question={
                questions[questionCategoryNumber].questions[questionNumber]
              }
              answers={answers}
              questionNumber={questionNumber}
              questionCategoryNumber={questionCategoryNumber}
              handleSelectAnswer={handleSelectAnswer}
            />
          )}
          <div className="flex gap-10 relative md:absolute md:bottom-[3rem] md:right-[4rem] justify-end md:justify-center my-[12px] md:my-[0px]">
            {displayFinishBtn && (
              <button
                className={`h-[30px] md:h-[40px] text-[11px] md:text-[16px] text-white bg-orange w-[90px] md:w-[130px] justify-end md:justify-center rounded-[28px] font-bold`}
                onClick={handleSubmitAssessment}
              >
                Finish
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Assessment
