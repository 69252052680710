import { useState } from "react"
import { Typography } from "../../typography"
import { BsChevronDown } from "react-icons/bs"

const FAQ = (option: { question: string; answer: string }) => {
  const [faqOpen, setFaqOpen] = useState(false)

  return (
    <div
      className={`px-[35px] py-[18px] border-b-[0.88px] border-b-[#96A0B5] border-solid transition-all delay-200 ease-in ${
        faqOpen ? "bg-gradient-to-t from-[#FFF9F933] to-[#ECF8FF80]" : ""
      }`}
    >
      <Typography
        variant="h6"
        className="text-[16px] lg:text-[25.5px] relative pr-[95px] leading-[25px] lg:leading-[45px]"
      >
        {option.question}
        <span
          className={`absolute right-0 top-[50%] translate-y-[-50%] text-[20px] cursor-pointer transition-all delay-100 ease-in ${
            faqOpen ? "text-[#121F3E] -rotate-180" : "text-[#6D7D93] rotate-0"
          }`}
          onClick={() => {
            setFaqOpen(!faqOpen)
          }}
        >
          <BsChevronDown />
        </span>
      </Typography>
      <Typography
        variant="p"
        className={`text-[12px] lg:text-[17px] overflow-hidden pr-[95px] leading-[20px] lg:leading-[29px] ${
          faqOpen ? "h-fit mt-[18px]" : "h-0"
        }`}
      >
        {option.answer}
      </Typography>
    </div>
  )
}

export default FAQ
