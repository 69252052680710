import { useState, useEffect } from "react"
import Logo from "../../../../assets/svg/gapstars-logo.svg"
import ProgressData from "../../../../components/core/progress"
import StatusObjectiveMappingQuestion from "../../../../components/questions/statusObjectiveMappingQuestion"
import { useLazyGetQuestionsQuery } from "../../../../services/questionService"
import { setQuestions } from "../../../../store/slices/question.slice"
import { useDispatch, useSelector } from "react-redux"
import Splash from "../../../../components/core/splash"
import QuestionCategoryType, {
  OptionsType,
  QuestionType,
} from "../../../../utils/types/question.type"
import { useNavigate } from "react-router-dom"
import { UrlSlugType } from "../../../../utils/enums/UrlSlug"
import { useMediaQuery } from "react-responsive"
import { useSubmitAssessmentMutation } from "../../../../services/assessmentService"
import toast from "react-hot-toast"
import { CategoryType } from "../../../../utils/types/assessmentTypes"
import {
  clearAssessmentData,
  setAssessmentAnswers,
} from "../../../../store/slices/assessment.slice"
import { deepCopy } from "../../../../utils/helpers/common"

const StatusObjectiveMappingAssessment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const questions = useSelector((state: any) => state.quiz.data)
  const userData = useSelector((state: any) => state.auth.user)
  const assessmentData: {
    answers: CategoryType[]
    currentQuestionNumber: number
    currentCategoryNumber: number
  } = useSelector((state: any) => state.assessment)
  const assessmentCategory = useSelector(
    (state: any) => state.assessmentCategory.assessmentCategory,
  )

  const [fetchQuestions, { isLoading: fetchQuestionsLoading, isSuccess }] =
    useLazyGetQuestionsQuery()
  const [submitAssessment, { isLoading: assessmentSubmitLoading }] =
    useSubmitAssessmentMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [questionCategoryNumber, setQuestionCategoryNumber] = useState(
    assessmentData.currentCategoryNumber,
  )
  const [questionNumber, setQuestionNumber] = useState(
    assessmentData.currentQuestionNumber,
  )
  const [selectedQuestion, setSelectedQuestion] = useState(
    questions?.[questionCategoryNumber]?.questions?.[questionNumber] || null,
  )
  const [displayFinishBtn, setDisplayFinishBtn] = useState(false)

  const isMobileView = useMediaQuery({ query: "(max-width: 600px)" })

  // For API input
  const [answers, setAnswers] = useState<CategoryType[]>(assessmentData.answers)

  useEffect(() => {
    setSelectedQuestion(
      questions?.[questionCategoryNumber]?.questions?.[questionNumber] || null,
    )
  }, [questionCategoryNumber, questionNumber, questions])

  useEffect(() => {
    let isComplete = true
    questions?.every((questionCategory: { id: string; questions: any[] }) => {
      const categoryAnswers = answers.find(
        (answer) => answer.categoryId === questionCategory.id,
      )
      if (
        categoryAnswers?.selectedOptions?.length !==
        questionCategory.questions.length
      ) {
        isComplete = false
      }
      return isComplete
    })

    setDisplayFinishBtn(isComplete)
  }, [answers, questionCategoryNumber, questionNumber, questions])

  const handleSelectAnswer = async (data: {
    question: any
    target: OptionsType
    currentState: OptionsType
  }) => {
    if (selectedQuestion?.id !== data?.question.id) {
      return
    }

    const category = answers.find(
      (answer) => answer.categoryId === data?.question?.categoryId,
    )

    let questionCategory: CategoryType | null | undefined = deepCopy(category)

    const answerParams = {
      questionId: data?.question?.id,
      target: data.target,
      currentState: data.currentState,
    }

    if (questionCategory && Object.keys(questionCategory).length > 0) {
      questionCategory.selectedOptions = [
        ...(questionCategory?.selectedOptions || []).filter(
          (option) => option.questionId !== answerParams.questionId,
        ),
        answerParams,
      ]
    } else {
      questionCategory = {
        categoryId: data?.question?.categoryId,
        selectedOptions: [answerParams],
      }
    }

    setAnswers([
      ...answers.filter(
        (category) => category.categoryId !== questionCategory?.categoryId,
      ),
      questionCategory,
    ])

    updateQuestionAndCategory(questionCategory)
  }

  const updateQuestionAndCategory = (questionCategory: any) => {
    let nextQuestionNumber = questionNumber
    let nextCategoryNumber = questionCategoryNumber

    const isLastQuestionInCategory =
      questionNumber === questions[questionCategoryNumber].questions.length - 1
    const isLastCategory = questionCategoryNumber === questions.length - 1

    if (isLastQuestionInCategory) {
      if (!isLastCategory) {
        nextCategoryNumber += 1
        nextQuestionNumber = 0
      }
    } else {
      nextQuestionNumber += 1
    }

    if (
      questionCategoryNumber !== nextCategoryNumber ||
      questionNumber !== nextQuestionNumber
    ) {
      setQuestionCategoryNumber(nextCategoryNumber)
      setQuestionNumber(nextQuestionNumber)

      if (!(nextCategoryNumber === 0 && nextQuestionNumber === 0)) {
        dispatch(
          setAssessmentAnswers({
            answers: [
              ...answers.filter(
                (category) =>
                  category.categoryId !== questionCategory?.categoryId,
              ),
              questionCategory,
            ],
            currentQuestionNumber: nextQuestionNumber,
            currentCategoryNumber: nextCategoryNumber,
          }),
        )
      }
    }
  }

  const handleSubmitAssessment = async () => {
    setIsLoading(true)
    const res: any = await submitAssessment({
      data: { answers: answers, userId: userData?.id },
      assessmentCategoryId: assessmentCategory?.id,
    })

    dispatch(clearAssessmentData())

    if (res?.data?.status) {
      navigate(UrlSlugType.ASSESSMENT_RESULTS, {
        state: { ...res.data?.data },
      })
      setIsLoading(false)
    }
  }

  const handleQuestionSelect = (
    questionNumber: number,
    questionCategoryNumber: number,
  ) => {
    setQuestionNumber(questionNumber)
    setQuestionCategoryNumber(questionCategoryNumber)
  }

  useEffect(() => {
    fetchQuestions(assessmentCategory?.id)
      .unwrap()
      .then((data: any) => {
        dispatch(setQuestions(data?.data?.questionCategories))
      })
      .catch((error: any) => {
        toast.error("Something went wrong", { duration: 2000 })
        navigate(UrlSlugType.HOME_PAGE)
      })
  }, [dispatch, navigate, fetchQuestions, assessmentCategory])

  if (fetchQuestionsLoading || assessmentSubmitLoading || isLoading) {
    return <Splash />
  }

  return (
    <div className="bg-bg-primary w-full h-[100vh] flex px-[22px 0]">
      <div className="container md:w-[25%] flex flex-col h-[90%]">
        <div className="w-full p-8">
          <img
            src={Logo}
            alt="Gapstars Logo"
            className="w-[130px] cursor-pointer"
            onClick={() => navigate(UrlSlugType.HOME_PAGE)}
          />
        </div>
        <div className=" flex flex-col h-full justify-center p-8">
          {isSuccess &&
            questions &&
            !isMobileView &&
            questions.map((question: QuestionCategoryType, index: number) => (
              <ProgressData
                handleQuestionSelect={handleQuestionSelect}
                key={question.id}
                {...question}
                questionCategoryNumber={questionCategoryNumber}
                questionNumber={questionNumber}
                answers={answers}
                index={index}
              />
            ))}
          {isSuccess && questions && isMobileView && (
            <div className="mt-[64px]">
              <p className="capitalize text-[#FFF] text-[14px] text-center">
                {`(${questionCategoryNumber + 1}/${questions.length}) `}
                {questions[questionCategoryNumber].categoryTitle}
              </p>
              <div className="flex gap-x-[2px] mt-2">
                {questions[questionCategoryNumber].questions.map(
                  (quiz: QuestionType, index: number) => (
                    <div
                      className={`p-[2px] h-[5px] text-white ${
                        questionNumber === index
                          ? "bg-[#DA7F41]"
                          : "bg-[#D9D9D9] "
                      } w-[100px] rounded-[1px]`}
                    />
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="md:hidden">
        <img
          src={Logo}
          alt="Gapstars Logo"
          className="w-[68px] cursor-pointer"
          onClick={() => navigate(UrlSlugType.HOME_PAGE)}
        />
      </div>
      <div
        id="question-card"
        className={`w-full md:w-[100%] md:bg-[#C6C6C6] text-[#232323] z-[100]`}
        style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))" }}
      >
        <div className="bg-[#C6C6C6] rounded-[31px] md:rounded-non flex flex-col p-[2%] h-full">
          {isSuccess && questions && (
            <StatusObjectiveMappingQuestion
              categoryId={questions[questionCategoryNumber].id}
              question={
                questions[questionCategoryNumber].questions[questionNumber]
              }
              questions={questions}
              answers={answers}
              questionNumber={questionNumber}
              questionCategoryNumber={questionCategoryNumber}
              setQuestionAnswer={handleSelectAnswer}
            />
          )}
          <div className="flex gap-10 mb-[1rem] justify-end mr-[5vw] mt-[2rem]">
            {displayFinishBtn && (
              <button
                className={`h-[30px] md:h-[40px] text-[11px] md:text-[16px] text-white bg-orange w-[90px] md:w-[130px] justify-end md:justify-center rounded-[28px] font-bold`}
                onClick={handleSubmitAssessment}
              >
                Finish
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatusObjectiveMappingAssessment
