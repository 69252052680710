import { OptionsType } from "./question.type"

export enum AssessmentCategory {
  DATA = "data",
  AGILE = "agile",
  FINANCE = "finance",
}

export type AnswerType = {
  questionId: string
  target?: OptionsType
  currentState?: OptionsType
  selectedOption?: OptionsType
}

export type CategoryType = {
  categoryId: string
  selectedOptions: AnswerType[]
}
