import { useState, ChangeEvent } from "react"
import Checkbox from "../checkBox"

const AnswerCard = (props: any) => {
  const [isActive, setIsActive] = useState(props?.checked)

  const handleAnswerCheck = (event: any) => {
    event.preventDefault()
    props.handleSelectAnswer(
      props.question,
      { id: props.id, optionTitle: props.answer, points: props.index + 1 },
      isActive,
    )
  }

  return (
    <div
      className={`w-full lg:w-[470px] min-h-[31px] md:min-h-[57px] ${
        props?.checked
          ? "bg-[#DA7F41] text-[#FFFFFF]"
          : "bg-[#C9BBB11A] text-[#232323]"
      } rounded-[20px] drop-shadow-[0_4px_4px_rgba(0,0,0,0.1)] mb-[11px] md:mb-[19px] cursor-pointer hover:bg-[#DA7F41] hover:text-[#FFFFFF]`}
      onClick={handleAnswerCheck}
    >
      <div
        className={`text-[11px] md:text-[21px] leading-[31px] flex items-center h-full min-h-[31px] md:min-h-[57px]`}
      >
        <Checkbox
          id={props.id}
          checked={props?.checked}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setIsActive(e.target.checked)
          }
        />
        <p className="pl-[32px]">{props?.answer}</p>
      </div>
    </div>
  )
}

export default AnswerCard
