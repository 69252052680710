import React from "react"

const Checkbox = (props: any) => {
  return (
    <div className="round ml-[24px]">
      <input type="checkbox" id={props.id} {...props} />
      <label htmlFor={props.id}></label>
    </div>
  )
}

export default Checkbox
