import {
  FLUSH,
  PAUSE,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
// import localStorage from "redux-persist/lib/storage"
import sessionStorage from "redux-persist/lib/storage/session"
import authReducer from "./slices/auth.slice"
import questionReducer from "./slices/question.slice"
import assessmentCategoryReducer from "./slices/assessmentCategory.slice"
import assessmentReducer from "./slices/assessment.slice"
import { authServiceApi } from "../services/authService"
import { questionServiceApi } from "../services/questionService"
import { questionCategoryServiceApi } from "../services/questionCategoryService"
import { assessmentCategoryServiceApi } from "../services/assessmentCategoryService"
import { assessmentServiceApi } from "../services/assessmentService"

const rootConfig = {
  key: "maturity-assessment",
  storage: sessionStorage,
  whitelist: ["auth", "assessmentCategory", "assessment"],
}

const persistedReducer = persistReducer(
  rootConfig,
  combineReducers({
    auth: authReducer,
    quiz: questionReducer,
    assessment: assessmentReducer,
    assessmentCategory: assessmentCategoryReducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [questionServiceApi.reducerPath]: questionServiceApi.reducer,
    [questionCategoryServiceApi.reducerPath]:
      questionCategoryServiceApi.reducer,
    [assessmentCategoryServiceApi.reducerPath]:
      assessmentCategoryServiceApi.reducer,
    [assessmentServiceApi.reducerPath]: assessmentServiceApi.reducer,
  }),
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PURGE],
      },
    }).concat([
      authServiceApi.middleware,
      questionServiceApi.middleware,
      questionCategoryServiceApi.middleware,
      assessmentCategoryServiceApi.middleware,
      assessmentServiceApi.middleware,
    ] as any),
})

setupListeners(store.dispatch)

export const persistedStore = persistStore(store)
