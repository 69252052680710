import FAQ from "./faq"

interface FaqAccordion extends React.HTMLAttributes<HTMLElement> {
  options: { question: string; answer: string }[]
}

export const FaqAccordion = (props: FaqAccordion) => {
  return (
    <div
      className="bg-transparent border-t-orange-dark border-t-[3px] border-solid mt-[55px]"
      {...props}
    >
      {props.options.map((option, index) => (
        <FAQ question={option.question} answer={option.answer} key={index} />
      ))}
    </div>
  )
}
