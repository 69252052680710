import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"
import { QuestionModel } from "../utils/types/questionTypes"

import SingleChoiceAssessmentPage from "../pages/private/user/SingleChoiceAssessment"
import StatusObjectiveMappingAssessment from "../pages/private/user/StatusObjectiveMappingAssessment"

// Layout
const MainLayout = React.lazy(() => import("../layouts/main"))

// pages
const LandingPage = React.lazy(() => import("../pages/public/user/LandingPage"))
const WelcomePage = React.lazy(() => import("../pages/private/user/Welcome"))

const AssessmentResultsPage = React.lazy(
  () => import("../pages/private/user/AssessmentResults"),
)
const ApproveRequest = React.lazy(
  () => import("../pages/public/admin/ApproveRequest"),
)
const ConfirmUser = React.lazy(
  () => import("../pages/public/admin/ConfirmUser"),
)

const PublicRoutes = (props: { assessmentCategory: any }) => {
  return useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: UrlSlugType.HOME_PAGE,
          element: <LandingPage />,
        },
        {
          path: UrlSlugType.WELCOME,
          element: <WelcomePage />,
        },
        {
          path: UrlSlugType.ASSESSMENT,
          element:
            props.assessmentCategory?.questionType ===
            QuestionModel.SINGLE_CHOICE ? (
              <SingleChoiceAssessmentPage />
            ) : (
              <StatusObjectiveMappingAssessment />
            ),
        },
        {
          path: UrlSlugType.ASSESSMENT_RESULTS,
          element: <AssessmentResultsPage />,
        },
        { path: UrlSlugType.APPROVE_USER, element: <ApproveRequest /> },
        { path: UrlSlugType.CONFIRM_USER, element: <ConfirmUser /> },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.WELCOME} /> },
  ])
}

export default PublicRoutes
