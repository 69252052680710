import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const assessmentCategoryServiceApi = createApi({
  reducerPath: "assessmentCategoryService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getAssessmentCategory: builder.query({
      query: (assessmentCategoryId: string) => ({
        url: ApiSlug.GET_ASSESSMENT_CATEGORY.replace(
          "{assessmentCategoryId}",
          assessmentCategoryId,
        ),
        method: "GET",
      }),
    }),

    getAssessmentCategories: builder.query({
      query: () => ({
        url: ApiSlug.GET_ASSESSMENT_CATEGORIES,
        method: "GET",
      }),
    }),

    addAssessmentCategory: builder.mutation({
      query: ({ data }) => ({
        url: ApiSlug.ADD_ASSESSMENT_CATEGORIES,
        method: "POST",
        body: data,
      }),
    }),
  }),
})

export const {
  useLazyGetAssessmentCategoryQuery,
  useLazyGetAssessmentCategoriesQuery,
  useAddAssessmentCategoryMutation,
} = assessmentCategoryServiceApi
