import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"

// Layout
const MainLayout = React.lazy(() => import("../layouts/main"))

// pages
const LandingPage = React.lazy(() => import("../pages/public/user/LandingPage"))
const EditQuestionPage = React.lazy(
  () => import("../pages/private/admin/EditQuestion"),
)
const ApproveRequest = React.lazy(
  () => import("../pages/public/admin/ApproveRequest"),
)
const ConfirmUser = React.lazy(
  () => import("../pages/public/admin/ConfirmUser"),
)

const PublicRoutes = () =>
  useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: UrlSlugType.HOME_PAGE, element: <LandingPage /> },
        { path: UrlSlugType.EDIT_QUESTION, element: <EditQuestionPage /> },
        { path: UrlSlugType.APPROVE_USER, element: <ApproveRequest /> },
        { path: UrlSlugType.CONFIRM_USER, element: <ConfirmUser /> },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.EDIT_QUESTION} /> },
  ])

export default PublicRoutes
