export interface IQuestion {
  id: string
  categoryId: string
  categoryKey: string
  questionTitle: string
  questionDescription: string | null
  questionType: string
  options: IQuestionOption[]
  createdAt: string | null
  updatedAt: string | null
  assessmentCategoryId: string
}

export interface IQuestionOption {
  id: string
  optionTitle: string
  points: number
}

export type QuestionOptionType = {
  id: string
  optionTitle: string
  points: number
}

export enum QuestionModel {
  SINGLE_CHOICE = "SINGLE_CHOICE",
  STATUS_OBJECTIVE_MAPPING = "STATUS_OBJECTIVE_MAPPING",
}
