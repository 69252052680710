import { twMerge } from "tailwind-merge"

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: string
}

export const Typography = (props: TypographyProps) => {
  const commonStyles = twMerge(
    `font-normal text-white text-left`,
    props.className,
  )
  // H1
  if (props.variant === "h1") {
    return (
      <h1
        {...props}
        className={twMerge(
          `text-5xl lg:text-[78px] leading-[60px] lg:leading-[85px]`,
          commonStyles,
        )}
      >
        {props.children}
      </h1>
    )
  }

  // H2
  if (props.variant === "h2") {
    return (
      <h2
        {...props}
        className={twMerge(
          `text-4xl lg:text-[64px] leading-[50px] lg:leading-[78px]`,
          commonStyles,
        )}
      >
        {props.children}
      </h2>
    )
  }

  // H3
  if (props.variant === "h3") {
    return (
      <h3
        {...props}
        className={twMerge(
          `text-3xl lg:text-[55px] leading-[40px] lg:leading-[82.5px]`,
          commonStyles,
        )}
      >
        {props.children}
      </h3>
    )
  }

  // H4
  if (props.variant === "h4") {
    return (
      <h4
        {...props}
        className={twMerge(
          `text-md lg:text-[36px] leading-[34px] lg:leading-[50px]`,
          commonStyles,
        )}
      >
        {props.children}
      </h4>
    )
  }

  // H5
  if (props.variant === "h5") {
    return (
      <h5
        {...props}
        className={twMerge(
          `text-sm lg:text-[32px] leading-[30px] lg:leading-[48px]`,
          commonStyles,
        )}
      >
        {props.children}
      </h5>
    )
  }

  // H6
  if (props.variant === "h6") {
    return (
      <h6
        {...props}
        className={twMerge(
          `text-xs lg:text-[28px] leading-[26px] lg:leading-[36px]`,
          commonStyles,
        )}
      >
        {props.children}
      </h6>
    )
  }

  // Paragraph
  if (props.variant === "p") {
    return (
      <p
        {...props}
        className={twMerge(
          `text-[12px] lg:text-[18.5px] leading-[20px] lg:leading-[25.5px]`,
          commonStyles,
        )}
      >
        {props.children}
      </p>
    )
  }
}
