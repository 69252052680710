import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const questionCategoryServiceApi = createApi({
  reducerPath: "questionCategoryService",
  baseQuery: baseQuery,
  tagTypes: ["questionCategories", "question"],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getCategories: builder.query({
      query: ({ assessmentCategoryId }) =>
        ApiSlug.GET_ALL_CATEGORIES.replace(
          "{assessmentCategoryId}",
          assessmentCategoryId,
        ),
    }),

    getQuestionsByCategory: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_QUESTIONS_BY_CATEGORY_KEY.replace(
          "{assessmentCategoryId}",
          data.assessmentCategoryId,
        ).replace("{questionCategoryKey}", data.categoryKey)}`,
    }),

    updateCategory: builder.mutation({
      query: ({ id, data, assessmentCategoryId }) => ({
        url: ApiSlug.UPDATE_CATEGORY.replace("{id}", id).replace(
          "{assessmentCategoryId}",
          assessmentCategoryId,
        ),
        method: "PUT",
        body: data,
      }),
    }),

    updateQuestion: builder.mutation({
      query: ({ id, assessmentCategoryId, data }) => ({
        url: ApiSlug.UPDATE_QUESTION.replace("{id}", id).replace(
          "{assessmentCategoryId}",
          assessmentCategoryId,
        ),
        method: "PUT",
        body: data,
      }),
    }),

    updateQuestionOption: builder.mutation({
      query: ({ id, assessmentCategoryId, data }) => ({
        url: ApiSlug.UPDATE_QUESTION_OPTIONS.replace("{id}", id).replace(
          "{assessmentCategoryId}",
          assessmentCategoryId,
        ),
        method: "PUT",
        body: data,
      }),
    }),
  }),
})

export const {
  useLazyGetCategoriesQuery,
  useLazyGetQuestionsByCategoryQuery,
  useUpdateCategoryMutation,
  useUpdateQuestionMutation,
  useUpdateQuestionOptionMutation,
} = questionCategoryServiceApi
