import { Link, LinkProps } from "react-router-dom"
import { twMerge } from "tailwind-merge"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  to?: string
}

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href?: string
  to?: string
}

const PrimaryButton: React.FC<ButtonProps | AnchorProps> = (props) => {
  const commonStyles = twMerge(
    `h-10 lg:h-12 flex items-center w-fit rounded-full text-[16px] lg:text-[20px] drop-shadow-md font-normal px-10 bg-orange text-white`,
    props.className,
  )

  if (props.href) {
    return (
      <a {...(props as AnchorProps)} className={commonStyles} target="_blank">
        {props.children}
      </a>
    )
  } else if (props.to) {
    return (
      <Link {...(props as ButtonProps & LinkProps)} className={commonStyles}>
        {props.children}
      </Link>
    )
  } else {
    return (
      <button {...(props as ButtonProps)} className={commonStyles}>
        {props.children}
      </button>
    )
  }
}

export default PrimaryButton
