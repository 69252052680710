import Logo from "../../../../assets/svg/gapstars-logo.svg"

import { useDispatch, useSelector } from "react-redux"
import { logOut } from "../../../../store/slices/auth.slice"

const NavBar = () => {
  const dispatch = useDispatch()

  const user = useSelector((state: any) => state.auth.user)

  const path = window.location?.pathname?.split("/")?.at(1) || ""

  const handleSignout = (event: any) => {
    event.preventDefault()
    dispatch(logOut())
  }

  return (
    <div className="container mx-auto max-w-none h-20 sm:h-[100px] flex items-center border-b-[1px] border-[#767575]">
      <div className="container md:mx-[5rem] max-w-none">
        <nav className="px-8 sm:px-0 ">
          <div className="flex justify-between items-center">
            <div>
              <a href="/">
                <img
                  src={Logo}
                  alt="Gapstars Logo"
                  className="cursor-pointer w-[130px] md:w-auto"
                />
              </a>
            </div>
            {user && Boolean(path) && (
              <div className="text-white">
                <button onClick={handleSignout}>Sign out</button>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default NavBar
