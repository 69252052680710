import { twMerge } from "tailwind-merge"

interface ImageContainerProps extends React.HTMLAttributes<HTMLElement> {
  link: string
  alt: string
}

export const ImageContainer = (props: ImageContainerProps) => {
  return (
    <div
      className={twMerge(
        `container w-full h-full relative mr-0 md:mr-10 max-w-[516px] max-h-[344px]`,
        props.className,
      )}
    >
      <div
        className={twMerge(
          `container w-full h-full rounded-[32px] overflow-hidden z-10 relative max-w-[380px] md:max-w-[516px] max-h-[344px]`,
          props.className,
        )}
      >
        <img src={props.link} alt={props.alt} />
      </div>
      <span
        className={twMerge(
          `block w-full h-full absolute border-orange border-2 rounded-[32px] top-[18px] left-[22px] md:top-[26px] md:left-[30px] z-0 max-w-[380px] md:max-w-[516px] max-h-[344px]`,
          props.className,
        )}
      ></span>
    </div>
  )
}
