import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type QuestionStateType = {
  data?: any
}

const initialState: QuestionStateType = {
  data: null,
}

const questionSlice = createSlice({
  name: "quiz",
  initialState: initialState,
  reducers: {
    setQuestions: (state, action: PayloadAction<any>): QuestionStateType => ({
      ...state,
      data: action.payload,
    }),
    clearQuestion: () => initialState,
  },
})

export const { setQuestions, clearQuestion } = questionSlice.actions
export default questionSlice.reducer
