import { twMerge } from "tailwind-merge"

interface LandingPageVideoProps extends React.HTMLAttributes<HTMLElement> {
  link: string
  title: string
}

export const LandingPageVideo = (props: LandingPageVideoProps) => {
  return (
    <div
      className={twMerge(
        `max-w-[1231px] w-full max-h-[908px] h-[300px] sm:h-[369px] md:h-[440px] xl:h-[700px] rounded-[18px] overflow-hidden`,
        props.className,
      )}
    >
      <iframe
        className="w-full h-full"
        src={props.link}
        title={props.title}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}
