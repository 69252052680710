import AnswerCard from "../../core/answerCard"
import { OptionsType } from "../../../utils/types/question.type"

const SingleChoiceQuestion = (props: any) => {
  const getCheckedStatus = (option: { id: string }) => {
    const category = props.answers?.find(
      (answer: { categoryId: string }) =>
        answer.categoryId === props.categoryId,
    )
    const selectedOption = category?.selectedOptions.find(
      (selectedOption: any) =>
        selectedOption?.questionId === props.question?.id,
    )
    return selectedOption?.selectedOption?.id === option?.id
  }

  return (
    <>
      <p className="text-[12px] md:text-[22px] lg:text-[24px] font-medium mb-[12px] md:mb-[24px]">
        {props?.question?.questionType}:
      </p>
      <p className="text-[16px] md:text-[28px] font-bold">
        {props?.question?.questionTitle}?
      </p>
      {props?.question?.questionDescription && (
        <p className="text-[16px] md:text-[16px] text-slate-400 mt-2">
          {props?.question?.questionDescription}
        </p>
      )}
      <div className="mt-[37px] md:mt-[42px]">
        {props?.question?.options?.map((option: OptionsType, index: number) => {
          return (
            <AnswerCard
              key={option?.id}
              id={option?.id}
              index={index}
              categoryId={props.categoryId}
              question={props?.question}
              answer={option?.optionTitle}
              checked={getCheckedStatus(option)}
              handleSelectAnswer={props?.handleSelectAnswer}
            />
          )
        })}
      </div>
    </>
  )
}

export default SingleChoiceQuestion
