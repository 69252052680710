import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"
import { objectToSearchParams } from "../utils/helpers/common"

export const authServiceApi: any = createApi({
  reducerPath: "authService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: ApiSlug.LOGIN,
        method: "POST",
        body: credentials,
      }),
    }),
    adminLogin: builder.mutation({
      query: (credentials) => ({
        url: ApiSlug.ADMIN_LOGIN,
        method: "POST",
        body: credentials,
      }),
    }),
    signUp: builder.mutation({
      query: (data) => ({
        url: ApiSlug.SIGN_UP,
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: ApiSlug.FORGOT_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: ApiSlug.RESET_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
    approveRequest: builder.mutation({
      query: ({ data, assessmentCategoryId }) => ({
        url: `${
          ApiSlug.APPROVE_USER
        }/assessment-category/${assessmentCategoryId}?${objectToSearchParams(
          data,
        )}`,
        method: "POST",
      }),
    }),
    confirmUser: builder.mutation({
      query: ({ data, assessmentCategoryId }) => ({
        url: `${
          ApiSlug.CONFIRM_USER
        }/assessment-category/${assessmentCategoryId}?${objectToSearchParams(
          data,
        )}`,
        method: "POST",
      }),
    }),
    fetchUser: builder.query({
      query: () => ({
        url: ApiSlug.ME,
        method: "GET",
      }),
    }),
    requestAccess: builder.mutation({
      query: ({ data }) => ({
        url: ApiSlug.REQUEST_ACCESS,
        method: "POST",
        body: data,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useAdminLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useApproveRequestMutation,
  useConfirmUserMutation,
  useLazyFetchUserQuery,
  useRequestAccessMutation,
} = authServiceApi
