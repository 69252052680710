import { ImageContainer } from "../imageContainer"
import { Typography } from "../typography"
import { twMerge } from "tailwind-merge"

interface TextWithImageProps extends React.HTMLAttributes<HTMLElement> {
  title: string
  description: string
  image: string
  alt: string
}

export const TextWithImage = (props: TextWithImageProps) => {
  return (
    <div
      {...props}
      className={twMerge(
        `container pt-[75px] lg:pt-[156px] flex gap-[40px] lg:gap-[70px] items-center flex-col-reverse sm:flex-row`,
        props.className,
      )}
    >
      <div className="container">
        <Typography variant="h3">{props.title}</Typography>
        <Typography
          variant="h5"
          className="mt-[20px] lg:mt-[71px] md:max-w-[600px]"
        >
          {props.description}
        </Typography>
      </div>
      <ImageContainer link={props.image} alt={props.alt} />
    </div>
  )
}
