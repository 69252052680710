import { QuestionType } from "../../../utils/types/question.type"

const ProgressData = (props: any) => {
  const onQuestionSelect = (selectedOption: any) => {
    props.handleQuestionSelect(
      selectedOption.questionNumber,
      selectedOption.categoryNumber,
    )
  }

  const getProgressBarStyles = (
    question: {
      categoryId: string
      id: string
    },
    questionIndex: number,
  ) => {
    let color: string | null = null

    if (
      props.questionCategoryNumber === props.index &&
      props.questionNumber === questionIndex
    ) {
      color = "bg-active-answer"
    } else {
      const categoryAnswers = props?.answers?.find(
        (answer: { categoryId: string }) =>
          answer.categoryId === question.categoryId,
      )

      if (!categoryAnswers) {
        color = "bg-answer"
      } else {
        const answer = categoryAnswers.selectedOptions?.find(
          (option: { questionId: string }) => option.questionId === question.id,
        )

        color = answer ? "bg-filled-answer" : "bg-answer"
      }
    }
    return color
  }

  return (
    <div className="mb-[32px]">
      <p
        onClick={() =>
          onQuestionSelect({
            categoryNumber: props.index,
            questionNumber: 0,
          })
        }
        className={`${
          props.questionCategoryNumber === props.index
            ? "text-[#FFFFFF]"
            : "text-[hsl(0,0,25%)]"
        }  text-[22px] md:text-[24px] lg:text-[29px] mb-[8px] hover:cursor-pointer`}
      >
        {props?.categoryTitle}
      </p>
      <div className="flex gap-x-[2px]">
        {props?.questions.map((question: QuestionType, index: number) => {
          return (
            <div
              onClick={() =>
                onQuestionSelect({
                  categoryNumber: props.index,
                  questionNumber: index,
                })
              }
              key={question.id}
              className={`p-[2px] h-[5px] text-white w-[100px] rounded-[1px] hover:cursor-pointer ${getProgressBarStyles(
                question,
                index,
              )}`}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ProgressData
