import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"
import LandingPage from "../pages/public/user/LandingPage"

// Layout
const MainLayout = React.lazy(() => import("../layouts/main"))

// pages

const LoginPage = React.lazy(() => import("../pages/public/user/Login"))
const SignUpPage = React.lazy(() => import("../pages/public/user/SignUp"))
const ForgotPasswordPage = React.lazy(
  () => import("../pages/public/user/ForgotPassword"),
)
const ResetPasswordPage = React.lazy(
  () => import("../pages/public/user/ResetPassword"),
)

const ApproveRequest = React.lazy(
  () => import("../pages/public/admin/ApproveRequest"),
)
const ConfirmUser = React.lazy(
  () => import("../pages/public/admin/ConfirmUser"),
)

const PublicUserRoutes = () => {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: UrlSlugType.HOME_PAGE,
          element: <LandingPage />,
        },
        { path: UrlSlugType.LOGIN, element: <LoginPage /> },
        { path: UrlSlugType.REGISTER, element: <SignUpPage /> },
        { path: UrlSlugType.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
        { path: UrlSlugType.RESET_PASSWORD, element: <ResetPasswordPage /> },
        { path: UrlSlugType.APPROVE_USER, element: <ApproveRequest /> },
        { path: UrlSlugType.CONFIRM_USER, element: <ConfirmUser /> },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.HOME_PAGE} /> },
  ])
  return routes
}
export default PublicUserRoutes
