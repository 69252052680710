import React, { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setAuth, setUser } from "./store/slices/auth.slice"
import AdminRoutes from "./routes/AdminRoutes"
import UserRoutes from "./routes/UserRoutes"
import { useLazyFetchUserQuery, useLoginMutation } from "./services/authService"
import { useLazyGetAssessmentCategoryQuery } from "./services/assessmentCategoryService"
import { setAssessmentCategory } from "./store/slices/assessmentCategory.slice"
import PublicUserRoutes from "./routes/PublicUserRoutes"
import PublicAdminRoutes from "./routes/PublicAdminRoutes"
import { UserTypes } from "./utils/types/userTypes"
import { AssessmentCategory } from "./utils/types/assessmentTypes"

function App() {
  const dispatch = useDispatch()
  const assessmentCategory = useSelector(
    (state: any) => state.assessmentCategory?.assessmentCategory,
  )

  const [fetchUser] = useLazyFetchUserQuery()
  const { data, isSuccess } = useLoginMutation({
    fixedCacheKey: "auth-login",
  })[1]
  const [fetchAssessmentCategory] = useLazyGetAssessmentCategoryQuery()

  const path = window.location?.pathname?.split("/")?.at(1) || ""

  const token = useSelector((state: any) => state.auth.token)
  const user = useSelector((state: any) => state.auth.user)

  const Routes = React.useMemo(() => {
    if (!token) {
      return path !== UserTypes.ADMIN ? (
        <PublicUserRoutes />
      ) : (
        <PublicAdminRoutes />
      )
    }
    if (user?.role === UserTypes.ADMIN) {
      return <AdminRoutes />
    }

    return <UserRoutes assessmentCategory={assessmentCategory} />
  }, [token, user, path, assessmentCategory])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAuth(data?.data))
      fetchUser().then(({ data }: any) => {
        dispatch(setUser(data?.data?.user))
      })
      fetchAssessmentCategory(path).then(({ data }: any) => {
        dispatch(setAssessmentCategory(data?.data?.assessmentCategory))
      })
    }
  }, [isSuccess, data, dispatch, fetchUser, fetchAssessmentCategory, path])

  useEffect(() => {
    if (path) {
      const existingAssessmentCategory =
        assessmentCategory?.assessmentCategoryPath === path
          ? assessmentCategory
          : {}
      dispatch(
        setAssessmentCategory({
          ...existingAssessmentCategory,
          assessmentCategoryPath: path,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, path])

  const getTitle = (path: string) => {
    const assessmentTypes: { [key: string]: string } = {
      data: "Data Maturity",
      agile: "Agile Maturity",
      default: "Maturity Assessments",
    }
    const key =
      Object.keys(assessmentTypes).find((key) => path.includes(key)) ||
      "default"
    return `Gapstars - ${assessmentTypes[key]} Assessment`
  }

  useEffect(() => {
    const title = getTitle(window.location.pathname)
    document.title = title
  }, [])

  return (
    <BrowserRouter
      basename={
        path !== UserTypes.ADMIN &&
        (Object as any).values(AssessmentCategory).includes(path)
          ? path
          : ""
      }
      children={Routes}
    />
  )
}

export default App
