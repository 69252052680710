export const objectToSearchParams = (obj: any, ignore: string[] = []) => {
  const params = new URLSearchParams()

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!ignore.includes(key)) {
        params.append(key, obj[key])
      }
    }
  }

  return params.toString()
}

export function textFormat(value: any) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export async function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}

export const deepCopy = (obj: any) => {
  if (!obj) return obj
  return JSON.parse(JSON.stringify(obj))
}
