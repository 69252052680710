import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const assessmentServiceApi = createApi({
  reducerPath: "assessmentService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    submitAssessment: builder.mutation({
      query: ({ data, assessmentCategoryId }) => ({
        url: ApiSlug.SUBMIT_ASSESSMENT.replace("{id}", assessmentCategoryId),
        method: "POST",
        body: data,
      }),
    }),
  }),
})

export const { useSubmitAssessmentMutation } = assessmentServiceApi
