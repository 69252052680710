import { useRef, MouseEvent } from "react"
import { BsArrowDown, BsArrowRightCircle } from "react-icons/bs"
import NavBar from "../../../../components/core/shared/navbar"
import { Typography } from "../../../../components/core/typography"

import { useMediaQuery } from "react-responsive"

const TextWithImage = (props: any) => {
  return (
    <div className={props.isMobileView ? "w-full" : "w-2/6"}>
      <div className="w-full">
        <img src={props.img} alt={props.alt} />
      </div>
      <div className="text-center bg-[#E8E4DC] min-h-[21rem] h-fit pt-[2rem] rounded-b-2xl flex flex-col">
        <h3 className="text-[1.25rem] font-bold">{props.title}</h3>
        <div className="px-[2rem] mt-[1rem]">
          <p>{props.content}</p>
        </div>
        <a
          className="bg-[#323232] block w-4/6 text-white mx-auto text-left pl-[0.75rem] rounded-xl py-[0.1rem] mt-auto mb-[1rem]"
          href={props.link}
        >
          <span>
            Take the Assessment{" "}
            <BsArrowRightCircle className="inline float-right mr-[0.5rem] mt-[0.25rem]" />
          </span>
        </a>
      </div>
    </div>
  )
}

const HomePage = () => {
  const topRef = useRef(null)
  const optRef = useRef(null)

  const isMobileView = useMediaQuery({ query: "(max-width: 600px)" })

  const handleClick = (event: any, ref: any) => {
    event.preventDefault()
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <div
        className={`container mx-auto bg-no-repeat bg-cover max-w-full bg-[#202020] overflow-x-hidden`}
        ref={topRef}
      >
        <NavBar />
        <div className="container h-full mx-auto px-4 h-full mb-[1rem] pb-[2rem] flex flex-col gap-y-[20vh]">
          <div
            className={`container mx-auto max-w-[843.86px] ${
              isMobileView ? `mt-[150px]` : `mt-[70px]`
            }`}
          >
            <div className="flex gap-[3rem] pt-[3rem] w-[50vw] h-full">
              <Typography
                variant="h1"
                className="text-6xl lg:text-[90px] font-bold text-center md:text-left"
              >
                Maturity Assessments
              </Typography>
              {!isMobileView && (
                <div className="container w-full h-full relative mr-0 md:mr-10 max-w-[516px]">
                  <div className="container w-full h-full rounded-[32px] overflow-hidden z-10 relative max-w-[380px] md:max-w-[516px] min-w-[250px]">
                    <img src={"/img/home-banner.png"} alt={"Banner"} />
                  </div>
                  <span className="block w-full h-full absolute border-orange border-2 rounded-[32px] top-[18px] left-[22px] md:top-[26px] md:left-[30px] z-0 max-w-[50vw] md:max-w-[50vw] max-h-[420px]"></span>
                </div>
              )}
            </div>
          </div>
          <p
            className="sm:right-0 items-center text-white cursor-pointer gap-2 text-sm flex justify-end self-end mb-[2rem5]"
            onClick={(e: MouseEvent<HTMLParagraphElement>) =>
              handleClick(e, optRef)
            }
          >
            Scroll down <BsArrowDown className="h-5 w-5" />
          </p>
        </div>
      </div>
      <div className="container px-8 sm:px-4 bg-[#D5CFC6] w-[100%] h-full max-w-full pb-[2%]">
        <div
          className="container lg:pt-[3rem] mx-auto flex flex-col items-center justify-center max-w-[1231px]"
          id="opt-this"
          ref={optRef}
        >
          <div
            className={
              isMobileView
                ? "flex flex-col w-full my-[3rem] gap-[3rem]"
                : "flex gap-[10rem] justify-center"
            }
          >
            <TextWithImage
              link="/data"
              img="/img/dma-banner.png"
              alt="Data"
              title="Data Maturity Assessment"
              content="Revolutionize your data game with our Data Maturity
                    Assessment! Uncover hidden insights, fortify your data
                    foundation, and propel your business forward. From data
                    discovery to advanced analytics, let us guide you on a
                    strategic path to maximize your data potential and drive
                    unparalleled success."
              isMobileView={isMobileView}
            />
            <TextWithImage
              link="/agile"
              img="/img/ama-banner.png"
              alt="Agile"
              title="Agile Maturity Assessment"
              content="Unlock your team's full potential with our Agile Maturity Assessment! Dive into a transformative journey that evaluates your project's agility, identifies strengths, and charts a roadmap for improvement. Elevate your workflow, embrace innovation, and conquer challenges with a tailor-made strategy for agile success."
              isMobileView={isMobileView}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
