import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type AssessmentCategoryType = {
  assessmentCategory: null | {
    assessmentCategoryName: string
    assessmentCategoryPath: string
    id: string
  }
}

const initialState: AssessmentCategoryType = {
  assessmentCategory: null,
}

const assessmentCategorySlice = createSlice({
  name: "assessmentCategory",
  initialState: initialState,
  reducers: {
    setAssessmentCategory: (
      state,
      action: PayloadAction<any>,
    ): AssessmentCategoryType => ({
      ...state,
      assessmentCategory: action.payload,
    }),
  },
})

export const { setAssessmentCategory } = assessmentCategorySlice.actions
export default assessmentCategorySlice.reducer
