import { useEffect, useState } from "react"
import { OptionsType } from "../../../utils/types/question.type"

const answerOptions = [
  {
    value: 0,
    label: "Impeded",
  },
  {
    value: 1,
    label: "In Transition",
  },
  {
    value: 2,
    label: "Sustainable",
  },
  {
    value: 3,
    label: "Agile",
  },
  {
    value: 4,
    label: "Ideal",
  },
]

const StatusObjectiveMappingQuestion = (props: any) => {
  const { question, setQuestionAnswer } = props
  const [selectedState, setSelectedState] = useState<null | {
    target?: number
    current?: number
  }>(null)

  const reset = () => {
    setSelectedState(null)
  }

  useEffect(() => {
    const category = props.answers.find(
      (answer: any) => answer.categoryId === props.categoryId,
    )
    let params = null
    if (category) {
      const answer = category.selectedOptions.find(
        (option: any) => option.questionId === props.question.id,
      )
      params = {
        target: answer?.target?.points,
        current: answer?.currentState?.points,
      }
    }
    setSelectedState(params)
  }, [props.answers, props.categoryId, props.question])

  const handleAnswerSelect = (event: any, label: string) => {
    const { target, current } = event

    let params = selectedState || {}

    if (label === "current") {
      params.current = current
    } else {
      params.target = target
    }

    setSelectedState({ ...params })

    setTimeout(() => {
      if (
        params.current !== null &&
        params.current !== undefined &&
        params.target !== null &&
        params.target !== undefined
      ) {
        const target = question?.options?.at(selectedState?.target)
        const currentState = question?.options?.at(selectedState?.current)

        setQuestionAnswer({
          question,
          target: { ...target },
          currentState: { ...currentState },
        })

        if (
          !(
            props.answers.length === props.questions.length &&
            props.answers[props.questionCategoryNumber].selectedOptions
              .length ===
              props.questions[props.questionCategoryNumber].questions.length
          )
        ) {
          reset()
        }
      }
    }, 300)
  }

  return (
    <>
      <div className="status-question-container">
        <div className="h-[50vh]">
          <div className="question-title">
            <p className="text-[16px] md:text-[1.5rem] font-bold">
              {question?.questionTitle}
            </p>
            {props?.question?.questionDescription && (
              <p className="text-[16px] md:text-[16px] mt-2 font-light">
                {props?.question?.questionDescription}
              </p>
            )}
          </div>
          <div className="items-list-wrapper">
            <div className="items-list">
              {question?.options?.map((option: OptionsType, index: number) => (
                <div className="item-wrapper" key={option?.id}>
                  <div className="item-value" key={index}>
                    {`${answerOptions.at(index)?.value}: ${
                      answerOptions.at(index)?.label
                    }`}
                  </div>
                  <div className="item-label">
                    <p>{option?.optionTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="options-title-wrapper">
            <p className="options-title">
              Select your current & target stages respectively.
            </p>
          </div>
          <div className="options-list mx-auto">
            <p className="label">Current: </p>
            {Array.from(Array(5).keys()).map((item) => {
              return (
                <div
                  className={`option ${
                    selectedState?.current === item ? "highlight" : ""
                  }`}
                  onClick={() =>
                    handleAnswerSelect({ current: item }, "current")
                  }
                  key={item}
                >
                  {item}
                </div>
              )
            })}
          </div>
          <div className="options-list mx-auto">
            <p className="label">Target: </p>
            {Array.from(Array(5).keys()).map((item) => {
              return (
                <div
                  className={`option ${
                    selectedState?.target === item ? "highlight" : ""
                  } ${
                    selectedState?.current === undefined ||
                    selectedState?.current > item
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      selectedState?.current !== undefined &&
                      selectedState?.current <= item
                    ) {
                      handleAnswerSelect({ target: item }, "target")
                    }
                  }}
                  key={item}
                >
                  {item}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusObjectiveMappingQuestion
